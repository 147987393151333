import { useCallback, useEffect, useRef, useState } from "react";

// simple session storage hook, shouldn't need this if on react 18
export function useSessionStorage(key: string) {
    // callback captures a closure so we have to use ref
    const valueRef = useRef<string | null>();
    const [value, setValue] = useState<string | null>(
        sessionStorage.getItem(key)
    );

    const onUpdate = useCallback(() => {
        const next = sessionStorage.getItem(key);
        if (next !== valueRef.current) {
            setValue(next);
        }
    }, [key]);

    useEffect(() => {
        valueRef.current = value;
    }, [value]);

    useEffect(() => {
        // register for updates
        window.addEventListener("storage", onUpdate);
        return () => window.removeEventListener("storage", onUpdate);
    }, [key]);

    return value;
}
